<template>
  <div class="add-music">
    <van-nav-bar :title="state.id ? '编辑音乐' : '添加音乐'">
      <template #left>
        <van-icon name="arrow-left" size="1.8rem" color="rgba(0, 0, 0, 0.8)" @click="goBack" />
      </template>

      <template #right>
        <van-icon name="replay" size="1.8rem" color="var(--primary-color)" @click="resetForm" />
      </template>
    </van-nav-bar>

    <!-- 添加提示语 -->
    <van-notice-bar
      left-icon="info-o"
      text="当前添加音乐仅本地有效, 如需同步多端使用, 建议下载列表json文件, 通过列表上传功能使用。"
    />

    <!-- 表单信息 -->
    <van-form class="add-form" scroll-to-error @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="state.title"
          name="title"
          label="歌曲"
          required
          clearable
          placeholder="歌曲名称"
          :maxlength="50"
          :rules="[{ required: true, message: '请填写歌曲名称' }]"
        />
        <van-field
          v-model="state.author"
          name="author"
          label="歌手"
          required
          clearable
          placeholder="歌手"
          :maxlength="20"
          :rules="[{ required: true, message: '请填写歌手' }]"
        />
        <van-field
          v-model="state.audioUrl"
          name="audioUrl"
          label="音源地址"
          required
          clearable
          type="textarea"
          :rows="3"
          placeholder="音源地址"
          :rules="[{ required: true, message: '请填写音源地址', validator: urlRule }]"
        />
        <van-field
          v-model="state.picUrl"
          name="picUrl"
          label="封面链接"
          clearable
          type="textarea"
          :rows="3"
          placeholder="封面链接(可不填)"
          :rules="[{ validator: urlRule }]"
        />
        <van-field
          v-model="state.lyric"
          name="lyric"
          label="歌词"
          clearable
          type="textarea"
          :rows="4"
          placeholder="歌词(可不填)"
        />
      </van-cell-group>
      <div style="margin: 2rem">
        <van-button round block type="primary" native-type="submit">提 交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive
} from 'vue'
import {
  NavBar,
  Icon,
  Dialog,
  Notify,
  Form,
  Field,
  CellGroup,
  Button,
  NoticeBar
} from 'vant'
import { useRoute, useRouter } from 'vue-router'
import { IsLinkUrl } from '@/utils/regs'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'add-music',

  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar
  },

  setup (props, context) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      id: true,
      title: '',
      author: '',
      picUrl: '',
      audioUrl: '',
      lyric: ''
    })

    onMounted(() => {
      initQuery()
    })

    // 链接地址校验
    function urlRule (value) {
      if (value && !IsLinkUrl(value)) {
        return '链接地址格式不正确'
      }
    }

    // 返回
    function goBack () {
      if (window.history.length <= 2) {
        router.replace('/')
      } else {
        router.back()
      }
    }

    // 重置表单处理
    function resetForm () {
      Dialog.confirm({
        message: '确认重置表单？'
      }).then(() => {
        state.title = ''
        state.author = ''
        state.picUrl = ''
        state.audioUrl = ''
        state.lyric = ''
        Notify({
          type: 'success',
          message: '已重置'
        })
      })
    }

    // 表单提交处理
    function onSubmit (values) {
      if (state.id) {
        const updateData = {
          ...values,
          id: state.id
        }
        delete updateData.loadError
        store.dispatch('updateMusicInfo', updateData)
        Notify({
          type: 'success',
          message: '修改成功'
        })
      } else {
        store.dispatch('addMusicList', values)
      }
      goBack()
    }

    // 初始化路由参数
    async function initQuery () {
      if (route.query.id) {
        state.id = route.query.id
        const currentItem = await store.dispatch('getMusicInfoById', route.query.id)
        if (currentItem) {
          state.title = currentItem.title
          state.author = currentItem.author
          state.picUrl = currentItem.picUrl
          state.audioUrl = currentItem.audioUrl
          state.lyric = currentItem.lyric
        }
      }
    }

    return {
      state,
      urlRule,
      goBack,
      resetForm,
      onSubmit
    }
  }
})
</script>
<style lang="scss" scoped>
.add-music {
  .add-form {
    margin-top: 1.6rem;
  }
}
</style>
